<template>
  <v-row justify="center" style="position: relative">
    <a
      href="#"
      @click="loadDetailSpesification"
      style="
        position: relative;
        top: 10px;
        right: 0;
        text-align: left;
        width: 170px;
        height: 100%;
        font-size: 12px;
      "
    >
      Lihat Detail spesifikasi
    </a>
    <v-dialog fullscreen v-model="dialog">
      <div
        style="
          background: white;
          padding: 0;
          overflow: hidden;
          min-height: 100vh;
        "
      >
        <!-- <v-card
        style="padding:10px; position:relative; background red; overflow:hidden;"
      > -->
        <v-toolbar style="position: relative; z-index: 1" elevation="1">
          <v-icon style="margin-right: 10px">mdi-compare</v-icon>
          <v-toolbar-title
            >DETAIL SPESIFIKASI ({{
              getSpecType === 1 ? 'Bahan Baku' : 'Umum'
            }})</v-toolbar-title
          >
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-toolbar-title class="orange--text mb-2">
            {{ items.vendor.name }}
          </v-toolbar-title>
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-toolbar-title class="orange--text mb-2">
            {{ getCatalogueDetail.product.name }}
          </v-toolbar-title>
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-toolbar-title class="orange--text mb-2"
            >#{{ getCatalogueDetail.product.code }}</v-toolbar-title
          >
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-toolbar-title class="orange--text mb-2">
            {{ getCatalogueDetail.merk !== '' ? getCatalogueDetail.merk : '-' }}
          </v-toolbar-title>
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" color="grey" style="color: white">
            <v-icon left> mdi-arrow-left-circle </v-icon>
            Back
          </v-btn>
        </v-toolbar>
        <v-row
          class="justify-content-center"
          style="background: rgba(219, 219, 219, 0.363)"
        >
          <v-col cols="12" style="padding-bottom: 0">
            <v-sheet class="mx-auto compare-sheet">
              <v-carousel
                hide-delimiters
                show-arrows
                class="mx-auto"
                style="height: 40vh"
              >
                <v-carousel-item
                  v-for="(item, index) in imgArr"
                  :key="index"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  style="padding-top: 7px"
                >
                  <v-img
                    style="height: 300px; margin: auto"
                    aspect-ratio="1.7"
                    contain
                    :src="`${item}`"
                    @error="imgError(index)"
                    alt="product_img"
                  >
                  </v-img>
                </v-carousel-item>
              </v-carousel>

              <div
                class="mx-auto"
                style="
                  width: 100%;
                  border: 1px solid rgba(0, 0, 0, 0.2);
                  border-left: none;
                  border-right: none;
                  background: rgba(219, 219, 219, 0.363);
                "
              >
                <v-card-text style="position: relative; color: #2e3634b6">
                  <v-row>
                    <v-col
                      style="
                        padding: 10px;
                        height: 40px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      cols="3"
                      class="title mb-2"
                    >
                      <h6
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-radius: 5px;
                          padding: 0 3px;
                        "
                      >
                        Vendor:
                        <p style="font-size: 14px; font-weight: 400">
                          {{ items.vendor.name }}
                        </p>
                      </h6>
                    </v-col>
                    <v-col
                      style="
                        padding: 10px;
                        height: 40px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      cols="5"
                      class="title mb-2"
                    >
                      <h6
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-radius: 5px;
                          padding: 0 3px;
                        "
                      >
                        Product: <br />
                        <p style="font-size: 14px; font-weight: 400">
                          {{ getCatalogueDetail.product.name }}
                        </p>
                      </h6>
                    </v-col>
                    <v-col
                      style="
                        padding: 10px;
                        height: 40px;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                      "
                      cols="3"
                      class="title mb-2"
                    >
                      <h6
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-radius: 5px;
                          padding: 0 3px;
                        "
                      >
                        Code:
                        <p style="font-size: 14px; font-weight: 400">
                          #{{ getCatalogueDetail.product.code }}
                        </p>
                      </h6>
                    </v-col>
                    <v-col
                      style="padding: 10px; height: 40px"
                      cols="1"
                      class="title mb-2"
                    >
                      <h6
                        style="
                          font-size: 12px;
                          font-weight: 600;
                          border-radius: 5px;
                          padding: 0 3px;
                        "
                      >
                        Merk:
                        <p style="font-size: 14px; font-weight: 400">
                          {{
                            getCatalogueDetail.merk !== ''
                              ? getCatalogueDetail.merk
                              : '-'
                          }}
                        </p>
                      </h6>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="12" style="padding-top: 0; min-height: 45vh">
            <v-col cols="12" style="padding: 0">
              <div
                class="text-left"
                style="padding-left: 20px; position: relative; top: 8px"
              >
                <v-btn-toggle
                  mandatory
                  v-model="tabName"
                  color="black accent-3"
                  style="background: transparent"
                >
                  <v-btn
                    elevation="4"
                    v-for="(item, index) in tabFunction(
                      this.getSpesificationDetail
                    ) > 0
                      ? getSpesificationDetail
                      : getSpesificationDetail"
                    :key="index"
                    :value="index"
                    style="border-radius: 0 20px 0 0"
                  >
                    {{ index }}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-col
                cols="12"
                style="
                  position: relative;
                  background: rgba(219, 219, 219, 0.1);
                  padding: 0 5px;
                "
              >
                <v-card
                  v-if="this.getSpesificationDetail !== undefined"
                  tile
                  elevation="2"
                  style="
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 0px 10px;
                  "
                >
                  <div>
                    <v-card
                      style="background: rgba(255, 255, 255, 0.1)"
                      elevation="0"
                      id="detailSpesificationTable"
                      v-if="getSpecType === 1"
                    >
                      <v-data-table
                        id="detailSpesificationTable"
                        :headers="spesificationInfomationHeader"
                        :items="getSpesificationDetail[tabName]"
                        hide-default-footer
                        class="transparent elevation-0 my-4"
                        hide-default-header
                        disable-pagination
                        disable-sort
                      >
                        <template #header="{}">
                          <thead class="v-data-table-header">
                            <tr>
                              <th
                                v-for="(h, i) in spesificationInfomationHeader"
                                :key="i"
                                class="text-center parent-header td-border-style"
                                :rowspan="h.children ? 1 : 2"
                                :colspan="h.children ? h.children.length : 1"
                              >
                                {{ h.text }}
                              </th>
                            </tr>
                            <tr>
                              <th
                                v-for="(h1, i1) in getSubHeader(
                                  spesificationInfomationHeader
                                )"
                                :key="i1"
                                class="text-center child-header td-border-style"
                              >
                                {{ h1.text }}
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template #item="props">
                          <tr>
                            <td style="width: 200px; text-align: center">
                              {{ props.item.spesification_attribute.name }}
                            </td>
                            <td style="width: 250px; text-align: center">
                              {{
                                props.item.standard !== ''
                                  ? props.item.standard
                                  : '-'
                              }}
                            </td>
                            <td style="width: 100px; text-align: center">
                              {{
                                props.item.unit !== null
                                  ? props.item.unit.name
                                  : '-'
                              }}
                            </td>
                            <td style="width: 100px; text-align: center">
                              {{
                                props.item.criteria === 1
                                  ? 'Major'
                                  : props.item.criteria === 2
                                  ? 'Kritikal'
                                  : '-'
                              }}
                            </td>
                            <td style="width: 250px; text-align: center">
                              {{
                                props.item.analysis_method !== ''
                                  ? props.item.analysis_method
                                  : '-'
                              }}
                            </td>
                            <td style="width: 250px; text-align: center">
                              {{
                                props.item.min_frequency_supplier !== ''
                                  ? props.item.min_frequency_supplier
                                  : '-'
                              }}
                            </td>
                            <td style="width: 250px; text-align: center">
                              {{
                                props.item.min_frequency_sanqua !== ''
                                  ? props.item.min_frequency_sanqua
                                  : '-'
                              }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                    <v-data-table
                      v-if="getSpecType === 2"
                      :headers="genheaders"
                      style="width: 100%"
                      :items="getSpesificationDetail[tabName]"
                      hide-default-footer
                    >
                    </v-data-table>
                  </div>
                </v-card>

                <v-card
                  v-else
                  tile
                  flat
                  style="
                    min-height: 40vh;
                    padding: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                  "
                >
                  <v-toolbar-title class="overline">
                    Data not found
                  </v-toolbar-title>
                </v-card>
              </v-col>
            </v-col>
          </v-col>
        </v-row>
        <!-- </v-card> -->
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['items', 'merk', 'photo'],
  data() {
    return {
      dialog: false,
      tabName: 'Dimensi',
      attribut: ['Kualitas', 'Merk', 'Dimensi'],
      headers: [
        {
          text: 'Parameter',
          value: 'spesification_attribute.name',
          width: '20%'
        },
        { text: 'Standar', value: 'standar' },
        { text: 'Unit', value: 'unit' },
        { text: 'Kriteria', value: 'kriteria' },
        {
          text: 'Metode Analisa',
          value: 'metode_analisa'
        },
        {
          text: 'Minimum Frekuensi',
          value: 'minimum_frekuensi'
        }
      ],
      genheaders: [
        {
          text: 'Parameter',
          value: 'spesification_attribute.name',
          width: '20%'
        },
        {
          text: 'Deskripsi',
          value: 'description'
        }
      ],

      spesificationInfomationHeader: [
        {
          text: 'Parameter',
          align: 'start',
          sortable: false,
          value: 'spesification_attribute.name'
        },
        { text: 'Standar', value: 'standard' },
        { text: 'Unit', value: 'unit.name' },
        { text: 'Kriteria', value: 'criteria' },
        {
          text: 'Metode Analisa',
          value: 'analysis_method'
        },
        {
          text: 'Minimum Frekuensi',
          value: 'min_frequency_sanqua',
          divider: true,
          children: [{ text: 'Supplier' }, { text: 'Sanqua' }]
        }
      ],
      imgArr: []
    }
  },
  computed: {
    ...mapGetters([
      'getCatalogueDetail',
      'getSpesificationDetail',
      'getSpecType',
      'getProductImageLink'
    ])
  },
  created() {},
  methods: {
    imgError(index) {
      this.images[index].photo =
        'http://10.10.20.8:8020/products/photo_1/ProductPhoto-g8extzpjvxqb5hcpc51be.png'
    },
    loadDetailSpesification() {
      const arr = Object.values(this.getCatalogueDetail.photo)
      const img = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
          img.push(
            arr[i].replace(
              'http://localhost/uploads/',
              `${this.getProductImageLink}`
            )
          )
        } else {
          if (i === 0) {
            img.push(
              'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png'
            )
          }
        }
      }
      this.imgArr = img
      this.dialog = true
    },
    cancel() {
      this.imgArr = []
      this.dialog = false
    },
    tabFunction(data) {
      return data
    },
    getSubHeader(headers) {
      let result = []
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children)
        })
      return result
    }
  }
}
</script>
<style lang="scss">
.dop {
  background: rgba(219, 219, 219, 0.363);
}
</style>
<style lang="sass" scoped>
#detailSpesificationTable
  $record-text-size:12px
  .v-data-table
    td
      padding: 12px 8px
      font-size: $record-text-size
      border-color: rgba(0,0,0,0.3)
      border-style: solid
    th
      border-color: rgba(0,0,0,0.3)
      border-style: solid
</style>
