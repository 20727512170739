<template>
  <div id="product-detail">
    <div class="product-detail-div" v-if="permission.read_perm === 1">
      <div id="rci" class="row-catalogue-image1">
        <v-row no-gutters>
          <v-col cols="12" style="height: 55px">
            <div
              cols="12"
              style="
                position: relative;
                padding: 10px 5px 10px;
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
              "
            >
              <v-icon>mdi-chevron-left</v-icon>
              <div @click="clearCatalogueDetail" style="cursor: pointer">
                <!-- <router-link to="/e-catalogue/catalogue-list">
                  Back</router-link
                > -->
                Back
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-card elevation="0" rounded>
              <v-img
                style="height: 340px"
                :src="activeImage"
                aspect-ratio="1"
                :contain="true"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="12" style="margin-top: 10px">
            <v-sheet class="mx-auto" style="background: transparent">
              <v-slide-group v-model="model" mandatory show-arrows>
                <v-slide-item
                  v-for="(item, index) in imgArr"
                  :key="index"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    elevation="0"
                    :color="active ? undefined : '#eee'"
                    :style="`height:80px; width:80px; ${
                      active ? 'border:2px solid indigo;' : undefined
                    }`"
                    class="mx-1"
                    rounded
                    @click="toggle"
                  >
                    <v-img
                      :src="`${item}`"
                      aspect-ratio="1"
                      :contain="true"
                    ></v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <v-card id="rcd" class="row-catalogue-detail1" flat>
        <v-col
          class="rcd-back-button"
          cols="12"
          style="
            position: relative;
            padding: 10px 5px 10px;
            flex-direction: row;
            margin-bottom: 10px;
            height: 45px;
          "
        >
          <v-icon>mdi-chevron-left</v-icon>
          <div @click="clearCatalogueDetail">
            <router-link to="/e-catalogue/catalogue-list"> Back</router-link>
          </div>
        </v-col>
        <v-col
          cols="12"
          style="
            position: relative;
            border-bottom: none;
            text-align: left;
            display: flex;
            justify-content: space-between;
          "
        >
          <h5>{{ getCatalogueDetail.product.name }}</h5>

          <v-btn @click="addToCart" color="green lighten-2" text icon>
            <!-- :color="
              this.$store.state.cart.savedData.find(
                a =>
                  a.product === getCatalogueDetail.product &&
                  a.vendor.clear_id === getCatalogueDetail.vendor.clear_id
              ) !== undefined
                ? 'black'
                : 'green lighten-2'
            " -->
            <v-tooltip bottom color="#9FA8DA">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-cart-plus</v-icon>
              </template>
              <span style="color: black">Add to cart</span>
              <!-- <span
                v-if="
                  $store.state.cart.savedData.find(
                    a =>
                      a.product === getCatalogueDetail.product &&
                      a.vendor.clear_id === getCatalogueDetail.vendor.clear_id
                  ) === undefined
                "
              >
                Add to cart</span
              >
              <span v-else>Already in cart</span> -->
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" style="position: relative">
          <v-col cols="12" style="padding: 0; margin-bottom: 20px">
            <p style="font-size: 16px; font-weight: bold">DESKRIPSI</p>
            <div style="max-height: 100px; overflow: auto">
              <p>
                {{ getCatalogueDetail.description }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            style="padding: 0; margin-bottom: 5px; margin-bottom: 5px"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">Code</p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px">
                #{{ getCatalogueDetail.product.code }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">Merk</p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px">
                {{
                  getCatalogueDetail.merk === null ||
                  getCatalogueDetail.merk === ''
                    ? '-'
                    : getCatalogueDetail.merk
                }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            style="padding: 0; margin-bottom: 5px; margin-bottom: 5px"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">Vendor / Supplier</p>
              <a
                style="
                  padding: 0 20px;
                  font-weight: 500;
                  color: #1976d2;
                  width: 200px;
                "
                class="aab"
                @click="viewDetailVendor(getCatalogueDetail.vendor)"
              >
                {{ getCatalogueDetail.vendor.name }}
              </a>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">
                Pembelian Terakhir
              </p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px">
                {{
                  getCatalogueDetail.last_ordered === null
                    ? '-'
                    : getCatalogueDetail.last_ordered | moment('DD MMMM YYYY')
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">
                Harga
                <!-- {{
                  getCatalogueDetail.last_ordered === null ||
                  getCatalogueDetail.last_ordered === ''
                    ? 'penawaran'
                    : 'terakhir'
                }} -->
                Terakhir
              </p>
              <p
                style="padding: 0 20px; font-weight: 500; width: 200px"
                v-if="!getCatalogueDetail.product.code.includes('AST')"
              >
                <span style="margin-right: 5px">{{
                  getCatalogueDetail.currency === null
                    ? ''
                    : getCatalogueDetail.currency.symbol
                }}</span>
                <span>
                  {{
                    getCatalogueDetail.last_price === null
                      ? '-'
                      : price(getCatalogueDetail.last_price)
                  }}
                </span>
              </p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px" v-else>
                -
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">Satuan</p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px">
                {{
                  getCatalogueDetail.purchase_uom_name === null
                    ? '-'
                    : getCatalogueDetail.purchase_uom_name
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">Dibeli Oleh</p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px">
                {{
                  getCatalogueDetail.last_purchase_plant === null ||
                  getCatalogueDetail.last_purchase_plant === ''
                    ? 'PT -'
                    : getCatalogueDetail.last_purchase_plant
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div
              style="
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              "
            >
              <p style="width: 250px; padding: 0 20px 0 0">
                Frekuensi Pembelian
              </p>
              <p style="padding: 0 20px; font-weight: 500; width: 200px">
                <!-- {{ getCatalogueDetail.purchase_frequency }} -->
                {{ purchaseHistory.length }}
              </p>
            </div>
          </v-col>

          <v-col cols="12" style="padding: 0; margin-top: 15px" class="d-flex">
            <div
              @click="specDetail(getCatalogueDetail, 'main')"
              style="height: 25px; width: 172px"
            >
              <DetailSpesification
                v-bind:items="getCatalogueDetail"
                v-bind:merk="catalogueMerk"
              />
            </div>
            <v-icon style="position: relative; top: -7px; right: 13px"
              >mdi-chevron-right</v-icon
            >
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 20px">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              "
            >
              <div>
                <v-btn
                  color="warning"
                  elevation="1"
                  outlined
                  rounded
                  text
                  style="font-size: 12px"
                  @click="showLocation(getCoordinate)"
                >
                  <v-icon dark> mdi-map-marker </v-icon>lokasi
                </v-btn>
              </div>
              <div style="margin-left: 10px">
                <v-btn
                  rounded
                  color="green"
                  :disabled="
                    getCatalogueDetail.brochure === null ||
                    getCatalogueDetail.brochure === ''
                      ? true
                      : false
                  "
                  elevation="1"
                  outlined
                  text
                  style="font-size: 12px"
                  @click="downloadBrochure(getCatalogueDetail.brochure)"
                >
                  <v-icon dark> mdi-download </v-icon>
                  Download Brosur
                </v-btn>
                <p
                  v-if="getCatalogueDetail.brochure === null"
                  style="
                    margin-top: 2px;
                    border-top: 1px solid red;
                    color: red;
                    font-size: 12px;
                    text-align: center;
                    opacity: 0.5;
                  "
                >
                  Belum ada brosur
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0; margin-bottom: 5px">
            <div id="googleMaps" class="gm-close">
              <GoogleMaps :coordinates="coordinate" />
            </div>
          </v-col>

          <v-col cols="12" style="position: relative; padding: 0">
            <div
              class="text-left"
              style="padding-left: 20px; position: relative; top: 8px"
            >
              <v-btn-toggle v-model="tabName" color="deep-purple accent-3">
                <v-btn
                  elevation="4"
                  v-for="(item, index) in tab"
                  :key="index"
                  :value="item.key"
                  style="border-radius: 0 20px 0 0"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-col
              v-if="tabName == 'supplier'"
              cols="12"
              style="position: relative; background: white; padding: 0"
            >
              <v-card
                tile
                elevation="2"
                style="border: 1px solid rgba(0, 0, 0, 0.2); padding: 10px"
                min-height="100"
              >
                <v-data-table
                  mobile-breakpoint="0"
                  dense
                  :loading="loading"
                  loading-text="Load data... Please wait"
                  :headers="supHeaders"
                  :items="getOtherSupplier"
                  hide-default-footer
                  style="border: 1px solid rgba(0, 0, 0, 0.4)"
                >
                  <template v-slot:[`item.code`]="{ item }">
                    <div style="font-size: 12px; width: 75px">
                      {{
                        item.vendor.code === null || item.vendor.code === ''
                          ? '-'
                          : item.vendor.code
                      }}
                    </div>
                  </template>
                  <template v-slot:[`item.supplier`]="{ item }">
                    <div style="font-size: 12px; width: 125px">
                      {{ item.vendor.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.merk`]="{ item }">
                    <div style="font-size: 12px; width: 75px">
                      {{ item.vendor.merk !== '' ? item.vendor.merk : '-' }}
                    </div>
                  </template>
                  <template v-slot:[`item.uom`]="{ item }">
                    <div style="font-size: 12px; width: 45px">
                      {{ item.uom }}
                    </div>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <div style="font-size: 12px; width: 75px">
                      {{
                        item.price === '-'
                          ? ' Rp. -'
                          : 'Rp. ' + price(item.price)
                      }}
                    </div>
                  </template>
                  <template v-slot:[`item.ratting`]="{ item }">
                    <div style="display: flex; flex-direction: row">
                      <v-rating
                        small
                        disabled
                        :value="
                          item.vendor.avg_rate !== null
                            ? Math.round(item.vendor.avg_rate / 2)
                            : 0
                        "
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        half-increments
                        hover
                        style="width: 100%; text-align: center"
                      ></v-rating>
                      <p style="margin: auto; font-size: 12px">
                        {{
                          item.vendor.avg_rate !== null
                            ? (item.vendor.avg_rate / 2).toFixed(1)
                            : '0.0'
                        }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:[`item.spesifikasi`]="{ item }">
                    <div
                      @click="specDetail(item, 'other')"
                      style="height: 25px"
                    >
                      <DetailSpesification
                        v-bind:items="item"
                        v-bind:merk="catalogueMerk"
                        :photo="getCatalogueDetail"
                      />
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>

            <v-col
              v-if="tabName == 'history'"
              cols="12"
              style="position: relative; background: white; padding: 0"
            >
              <v-card
                tile
                elevation="2"
                style="border: 1px solid rgba(0, 0, 0, 0.2); padding: 10px"
                min-height="100"
              >
                <v-data-table
                  mobile-breakpoint="0"
                  dense
                  :loading="loading"
                  loading-text="Load data... Please wait"
                  :headers="purchaseHistoryHeaders"
                  :items="purchaseHistory"
                  :items-per-page="10"
                  style="border: 1px solid rgba(0, 0, 0, 0.4)"
                >
                  <template v-slot:[`item.product`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          style="
                            text-align: left;
                            width: 250px;
                            font-size: 12px;
                            padding: 0;
                          "
                        >
                          {{
                            `${
                              item.product.code !== null
                                ? '(' + item.product.code + ')'
                                : ''
                            } ${item.product.name}`
                          }}
                        </div>
                      </template>
                      <span>
                        {{
                          `${
                            item.product.code !== null
                              ? '(' + item.product.code + ')'
                              : ''
                          } ${item.product.name}`
                        }}</span
                      >
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.vendor`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          style="
                            text-align: left;
                            width: 200px;
                            font-size: 12px;
                            padding: 0;
                          "
                        >
                          {{
                            item.vendor !== null
                              ? `${
                                  item.vendor.code !== null
                                    ? '(' + item.vendor.code + ')'
                                    : ''
                                } ${
                                  item.vendor.name !== null
                                    ? '(' + item.vendor.name + ')'
                                    : ''
                                }`
                              : ''
                          }}
                        </div>
                      </template>
                      <span>{{
                        item.vendor !== null
                          ? `${
                              item.vendor.code !== null
                                ? '(' + item.vendor.code + ')'
                                : ''
                            } ${
                              item.vendor.name !== null
                                ? '(' + item.vendor.name + ')'
                                : ''
                            }`
                          : ''
                      }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.budget_price_per_unit`]="{ item }">
                    <td style="font-size: 12px">
                      {{ price(item.budget_price_per_unit) }}
                    </td>
                  </template>
                  <template v-slot:[`item.budget_price_total`]="{ item }">
                    <td style="font-size: 12px">
                      {{ price(item.budget_price_total) }}
                    </td>
                  </template>
                  <template v-slot:[`item.uom`]="{ item }">
                    <td style="font-size: 12px">
                      {{ item.uom.name }}
                    </td>
                  </template>
                  <template v-slot:[`item.last_price`]="{ item }">
                    <td style="font-size: 12px">
                      {{ price(item.last_price) }}
                    </td>
                  </template>
                  <template v-slot:[`item.request_no`]="{ item }">
                    <div style="width: 120px; font-size: 12px">
                      {{ item.request_no }}
                    </div>
                  </template>
                  <template v-slot:[`item.employee`]="{ item }">
                    <div style="width: 210px; font-size: 12px">
                      {{ item.employee.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.company`]="{ item }">
                    <div style="width: 210px; font-size: 12px">
                      {{ item.company.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.department`]="{ item }">
                    <div style="width: 140px; font-size: 12px">
                      {{ item.department.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.category_item`]="{ item }">
                    <div style="width: 120px; font-size: 12px">
                      {{ item.category_item.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.project`]="{ item }">
                    <div style="width: 210px; font-size: 12px">
                      {{ item.project != null ? item.project.name : '-' }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col
              v-if="tabName == 'catalogue'"
              cols="12"
              style="position: relative; background: white; padding: 0"
            >
              <v-card
                tile
                elevation="2"
                style="border: 1px solid rgba(0, 0, 0, 0.2); padding: 10px"
                min-height="100"
              >
                <v-data-table
                  mobile-breakpoint="0"
                  dense
                  :loading="loading"
                  loading-text="Load data... Please wait"
                  :headers="vendorCatalogueHeaders"
                  :items="vendorCatalogue"
                  :items-per-page="10"
                  style="border: 1px solid rgba(0, 0, 0, 0.4)"
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    itemsPerPageOptions: [1, 3, 5, 10, 15, 100, 1000]
                  }"
                >
                  <template v-slot:top>
                    <div
                      style="
                        border: thin solid rgba(0, 0, 0, 0.12);
                        cursor: auto;
                        padding: 0 15px;
                        height: 75px;
                        display: flex;
                        overflow: hidden;
                        width: 100%;
                      "
                    >
                      <div
                        style="
                          position: relative;
                          top: 15px;
                          margin: 0 15px 0 5px;
                          font-size: 12px;
                          height: 45px;
                          width: 100%;
                        "
                      >
                        <v-text-field
                          v-model="searchVendorItem"
                          label="ketik nama barang.."
                          style="width: 100%"
                          outlined
                          dense
                          @keyup.enter="getVendorCatalogue"
                        ></v-text-field>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.product`]="{ item }">
                    <div style="width: 210px; font-size: 12px">
                      {{ item.product.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.uom`]="{ item }">
                    <div style="width: 70px; font-size: 12px">
                      {{ item.uom_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.currency`]="{ item }">
                    <div style="width: 70px; font-size: 12px">
                      {{ item.currency.code }}
                    </div>
                  </template>
                  <template v-slot:[`item.last_price`]="{ item }">
                    <div style="width: 120px; font-size: 12px">
                      {{ price(item.last_price) }}
                    </div>
                  </template>
                  <template v-slot:[`item.purchase_frequency`]="{ item }">
                    <div style="width: 70px; font-size: 12px">
                      {{ item.purchase_frequency }}
                    </div>
                  </template>
                  <template v-slot:[`item.last_ordered`]="{ item }">
                    <div style="width: 120px; font-size: 12px">
                      {{ item.last_ordered }}
                    </div>
                  </template>
                  <template v-slot:[`item.last_purchase_plant`]="{ item }">
                    <div style="width: 210px; font-size: 12px">
                      {{ item.last_purchase_plant }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-col>
        </v-col>
      </v-card>
      <div id="rvd" class="row-vendor-detail1">
        <v-icon class="rvd-close-icon" @click="closeDetailVendor" small
          >mdi-close</v-icon
        >
        <VendorDetail />
      </div>
    </div>

    <div style="height: 80vh; padding: 20px" v-else>
      <v-card
        flat
        style="
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          style="position: absolute; top: 10px; left: 10px"
          @click="clearCatalogueDetail"
        >
          <v-icon>mdi-chevron-left</v-icon>
          <router-link to="/e-catalogue/catalogue-list"> Back</router-link>
        </div>
        <v-toolbar-title class="overline">
          You Can't Access This Page</v-toolbar-title
        >
      </v-card>
    </div>

    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      > </v-card
    ><v-snackbar top color="success" v-model="snackbar" :timeout="timeout">
      {{ addToCartText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import DetailSpesification from '../../components/eCatalogue/catalogue_detail/DetailSpec'
import GoogleMaps from '../../components/eCatalogue/catalogue_detail/GoogleMaps'
import VendorDetail from '../../components/eCatalogue/catalogue_detail/DetailVendor'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  name: 'CatalogueDetail',
  data: () => ({
    production:
      process.env.VUE_APP_BUILD_TYPE === 'production' ||
      process.env.VUE_APP_BUILD_TYPE === 'domain',
    menu_key: 21,
    permission: {},
    application: {
      id: 8,
      name: 'eCatalogue'
    },
    proc: buildType.apiURL('proc'),
    catalogueMerk: '',
    tab: [
      { name: 'Supplier lainnya', key: 'supplier' },
      { name: 'History transaksi', key: 'history' },
      { name: 'Katalog Vendor', key: 'catalogue' }
    ],
    tabName: 'supplier',
    drawer: false,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    attribut: ['Kualitas', 'Merk', 'Dimensi'],
    loading: false,
    isLocation: false,
    coordinate: {
      lat: 0,
      lng: 0
    },
    supHeaders: [
      {
        text: 'No',
        align: 'start',
        value: 'no',
        width: '7%'
      },
      { text: 'Code', value: 'code', width: '10%' },
      { text: 'Supplier', value: 'supplier', width: '18%' },
      { text: 'Merk', value: 'merk', width: '10%' },
      { text: 'UOM', value: 'uom', width: '8%' },
      { text: 'Harga', value: 'price', width: '12%' },
      { text: 'Ratting', value: 'ratting', align: 'center', width: '20%' },
      { text: '', value: 'spesifikasi', width: '15%' }
    ],
    expandVendorDetail: false,
    model: null,
    activeImage: '',
    imgArr: [],
    snackbar: false,
    addToCartText: 'Berhasil ditambahkan',
    timeout: 2000,
    purchaseHistory: [],
    purchaseHistoryHeaders: [
      {
        text: 'No. Request',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Product',
        value: 'product',
        align: 'left',
        sortable: false
      },
      {
        text: 'Vendor',
        value: 'vendor',
        align: 'left',
        sortable: false
      },
      {
        text: 'Qty',
        value: 'qty',
        align: 'left',
        sortable: false
      },
      {
        text: 'Uom',
        value: 'uom',
        align: 'left',
        sortable: false
      },
      {
        text: 'Budget/unit',
        value: 'budget_price_per_unit',
        align: 'left',
        sortable: false
      },
      {
        text: 'Total',
        value: 'budget_price_total',
        align: 'left',
        sortable: false
      },
      {
        text: 'Harga Terakhir',
        value: 'last_price',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category_item',
        align: 'left',
        sortable: false
      },
      {
        text: 'Project',
        value: 'project',
        align: 'left',
        sortable: false
      }
    ],
    vendorCatalogue: [],
    vendorCatalogueHeaders: [
      {
        text: 'Product',
        value: 'product',
        align: 'left',
        sortable: false
      },
      {
        text: 'Satuan',
        value: 'uom',
        align: 'left',
        sortable: false
      },
      {
        text: 'Currency',
        value: 'currency',
        align: 'left',
        sortable: false
      },
      {
        text: 'Harga terakhir',
        value: 'last_price',
        align: 'left',
        sortable: false
      },
      {
        text: 'Terakhir dibeli',
        value: 'last_ordered',
        align: 'left',
        sortable: false
      },
      {
        text: 'Dibeli oleh',
        value: 'last_purchase_plant',
        align: 'left',
        sortable: false
      },
      {
        text: 'Frekuensi',
        value: 'purchase_frequency',
        align: 'left',
        sortable: false
      }
    ],
    searchVendorItem: '',
    limit: 10,
    offset: 0
  }),
  components: {
    DetailSpesification,
    GoogleMaps,
    VendorDetail
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  mounted() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === 1 ||
        arr[i].application.id === this.application.id
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then((result) => {
            this.initImage()
            this.permission = result.data
            this.otherSupplierCatalogue()
            this.getPurchaseTransactionHistory()
            this.getVendorCatalogue()
            setTimeout(() => {
              this.setIsLoading(false)
            }, 500)
          })
          .catch((error) => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              setTimeout(() => {
                this.setIsLoading(false)
              }, 500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCatalogueDetail',
      'getOtherSupplier',
      'getCoordinate',
      'getIsLoading',
      'getUserProfile',
      'getProductImageLink'
    ])
  },
  watch: {
    model() {
      this.activeImage = this.imgArr[this.model]
    }
  },
  methods: {
    ...mapActions([
      'logout',
      'otherSupplierCatalogue',
      'spesificationDetail',
      'brochureDownload',
      'menu_access',
      'vendorDetail'
    ]),
    ...mapMutations(['setCatalogueDetail', 'setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          title: pModalType === 'success' ? 'Success' : pModalType,
          icon: pModalType,
          text: pStatusMsg,
          timer: 3000
        })
        .then((r) => {})
    },
    customSearch(value, search, item) {
      return Object.values(item.product).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      )
    },
    drawerSet(val) {
      this.drawer = val
    },
    home() {
      this.$router.push('/')
    },
    initImage() {
      const arr = Object.values(this.getCatalogueDetail.photo)
      const img = []

      // if (arr.findIndex(element => element === null) !== -1) {
      //   arr.splice(arr.findIndex(element => element === null))
      // }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
          img.push(
            arr[i].replace(
              'http://localhost/uploads/',
              `${this.getProductImageLink}`
            )
          )
        } else {
          if (i === 0) {
            img.push(
              'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-ys19dvd8nvl3xqlu1o4u2z.png'
            )
          }
        }
      }
      this.imgArr = img
    },
    specDetail(item, param2, param3) {
      if (param2 === 'main') {
        this.catalogueMerk = item.merk
      } else {
        this.catalogueMerk = item.vendor.merk
      }
      this.spesificationDetail([item.id, 0, 1000, ''])
    },
    clearCatalogueDetail() {
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/e-catalogue/catalogue-list') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/e-catalogue/catalogue-list')
        }
      } else {
        this.$router.push('/e-catalogue/catalogue-list')
      }
    },
    downloadBrochure(item) {
      this.brochureDownload(item)
    },
    showLocation(item) {
      const element = document.getElementById('googleMaps')
      if (element.classList[0] === 'gm-close') {
        element.classList.remove('gm-close')
        element.classList.add('gm-open')
        if (item.latitude === null && item.longitude === null) {
          this.coordinate = {
            lat: -6.257035162544363,
            lng: 106.76446994117893
          }
        } else {
          this.coordinate = {
            lat: Number(item.latitude),
            lng: Number(item.longitude)
          }
        }
      } else {
        element.classList.remove('gm-open')
        element.classList.add('gm-close')
        this.coordinate = {
          lat: -6.257035162544363,
          lng: 106.76446994117893
        }
      }
    },
    price(val) {
      if (val != null) {
        const reverse = val.toString().split('').reverse().join('')
        var ribuan = reverse.match(/\d{1,3}/g)
        return ribuan.join(',').split('').reverse().join('')
      }
      return '-'
    },
    viewDetailVendor(data) {
      this.vendorDetail(data.id)
      const element1 = document.getElementById('rcd')
      const element2 = document.getElementById('rvd')

      element1.classList.remove('row-catalogue-detail1')
      element2.classList.remove('row-vendor-detail1')
      element1.classList.add('row-catalogue-detail2')
      element2.classList.add('row-vendor-detail2')
    },
    closeDetailVendor() {
      const element1 = document.getElementById('rcd')
      const element2 = document.getElementById('rvd')
      element1.classList.remove('row-catalogue-detail2')
      element2.classList.remove('row-vendor-detail2')
      element1.classList.add('row-catalogue-detail1')
      element2.classList.add('row-vendor-detail1')
    },
    addToCart() {
      // if (
      //   this.$store.state.cart.savedData.find(
      //     a =>
      //       a.product === this.getCatalogueDetail.product &&
      //       a.vendor.clear_id === this.getCatalogueDetail.vendor.clear_id
      //   ) === undefined
      // ) {
      this.$store.commit('setCartList', this.getCatalogueDetail)
      this.snackbar = true
      // }
    },
    getPurchaseTransactionHistory() {
      axios
        .get(
          `${this.proc}fpb/transaction_history?product_code=${this.getCatalogueDetail.product.code}&vendor_id=${this.getCatalogueDetail.vendor.id}&offset=0&limit=all`
        )
        .then((res) => {
          console.log('getPurchaseTransactionHistory>>>', res)
          if (res.data.status_code == '00') {
            this.purchaseHistory = res.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getVendorCatalogue() {
      axios
        .get(
          `${this.proc}vendor/catalogue/list?offset=${this.offset}&keyword=${this.searchVendorItem}&limit=${this.limit}&vendor_id=${this.getCatalogueDetail.vendor.id}`
        )
        .then((res) => {
          console.log('getVendorCatalogue>>>', res)
          if (res.data.status_code == '00') {
            this.vendorCatalogue = res.data.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
#product-detail {
  p,
  h6,
  h4 {
    margin: 0;
  }
}
#googleMaps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}
.gm-open {
  height: 400px;
}
.gm-close {
  height: 0;
}
.aab:hover {
  text-decoration: 1px underline;
}
.product-detail-div {
  min-height: 50vh;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#rcd {
  height: 100%;
  position: relative;
  transition: 0.3s;

  .rcd-back-button {
    display: none;
  }
}
.row-catalogue-detail1 {
  width: 70%;
}
.row-catalogue-detail2 {
  width: 40%;
}
#rvd {
  overflow: hidden;
  background: white;
  margin-left: 10px;
  position: relative;
  transition: 0.3s;
  .rvd-close-icon {
    position: relative;
    left: 5px;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    border: 1px solid black;
    box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
  }
}
.row-vendor-detail1 {
  width: 0;
}
.row-vendor-detail2 {
  width: 30%;
}

#rci {
  height: 100%;
}

.row-catalogue-image1 {
  width: 30%;
  padding: 0 30px;
}
@media (max-width: 576px) {
  #rvd {
    position: fixed;
    left: 0;
    top: 120px;
    right: 0;
    z-index: 3;
  }
  .row-vendor-detail1 {
    height: 0;
    border: none;
    width: 0;
    border-radius: 5px;
  }
  .row-vendor-detail2 {
    height: 80vh;
    border: 1px solid grey;
    width: 95%;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  .row-catalogue-detail1 {
    width: 100%;
  }
  .row-catalogue-detail2 {
    width: 100%;
  }
  #rci {
    display: none;
  }
  #rcd {
    .rcd-back-button {
      display: flex;
    }
  }
}
</style>
